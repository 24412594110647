const REQ_PREFIX = 'https://gateway.di79.com/account-service/shopify-cod/'
const REQ_SUFFIX = '/pass'

export function createCodEnable(shop) {
  return fetch(REQ_PREFIX + shop + REQ_SUFFIX, {
    method: "POST",
  })
}

export function queryCodEnable(shop) {
  return fetch(REQ_PREFIX + shop + REQ_SUFFIX, {
    method: "GET",
  })
}

export function updateCodEnable(shop, enable) {
  return fetch(REQ_PREFIX + shop + "/" + enable + REQ_SUFFIX, {
    method: "PUT"
  })
}
