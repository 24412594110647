import React, { useState } from 'react';

import {Button, MenuItem, Select, Typography, Box} from "@material-ui/core";

export default function InitializeForm({ submit }) {

  const languageOptions = [
    { label: 'English', value: 'english' },
    { label: 'Spanish', value: 'spanish' },
    { label: 'French', value: 'french' },
  ];

  // 使用 useState 来管理下拉框的值
  const [selectedValue, setSelectedValue] = useState(languageOptions[0].value);

  // 处理下拉框值变化的函数
  const handleDropdownChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
      <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
      >
          <div>
              <Typography variant="h2">请选择你的语言</Typography>
              <Select
                  value={selectedValue}
                  onChange={handleDropdownChange}
              >
                  {
                      languageOptions.map((option) => (
                          <MenuItem value={option.value}>{option.label}</MenuItem>
                      ))
                  }
              </Select>

              <Button variant="contained" color="primary" onClick={submit}>
                  继续
              </Button>
          </div>
      </Box>
);


};
