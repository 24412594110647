import React, {useState, useEffect} from 'react';
import {queryCodEnable, updateCodEnable} from "../model/CodEnable";
import {
    AppBar,
    Box,
    Button,
    IconButton,
    Toolbar,
    Typography,
    Container,
    Switch,
    Dialog,
    DialogTitle, DialogContent, DialogContentText, DialogActions
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

const SettingForm = ({ shop }) => {

    const classes = useStyles();

    // 对话框开始
    const [isDialogOpen, setIsDialogOpen] = useState(false)

    function handleClose() {
        setIsDialogOpen(false)
    }
    // 对话框结束

    // COD 是否启用 开始

    const [isCodFormEnabled, setIsCodFormEnabled] = useState(false);

    const trySwitch = () => {

        if (isCodFormEnabled === true) {
            setIsDialogOpen(true)
            return
        }

        updateCodEnable(shop(), (! isCodFormEnabled) ? 1 : 0).then(() => setIsCodFormEnabled(! isCodFormEnabled))
    }
    const switchThenClose = () => {
        updateCodEnable(shop(), (! isCodFormEnabled) ? 1 : 0).then(() => setIsCodFormEnabled(! isCodFormEnabled))
        handleClose()
    }
    // COD 是否启用 结束


    return (
      <>
        <Container
            maxWidth="md"
        >
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Cash On Delivery (COD) 插件程序
                    </Typography>
                </Toolbar>
            </AppBar>
            <p>
            货到付款启用/隐藏
                <Switch checked={isCodFormEnabled} onClick={trySwitch}/>
            </p>
            <Dialog
                open={isDialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"确认关闭？"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        关闭后，您的商店将无法使用货到付款功能，是否要关闭？
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={switchThenClose} color="primary" autoFocus>
                        确定
                    </Button>
                    <Button onClick={handleClose} color="secondary">
                        取消
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
      </>
    );
};

export default SettingForm
