import './App.css';
import React, {useEffect, useState} from "react";
import { queryCodEnable, createCodEnable } from "./model/CodEnable"

import InitializeForm from "./pages/InitializeForm";
import SettingForm from "./pages/SettingForm";


function App() {

    const [toggle, setToggle] = useState(true)

    const shop = window.shop

    function getShop() {
        return shop
    }

    useEffect(() => {
        queryCodEnable(shop)
            .then(r => r.json())
            .then(object => {
                console.log(JSON.stringify(object))
                if (object.data === undefined) {
                    setToggle(false)
                } else {
                    setToggle(true)
                }
            })
    }, [toggle])

    const toSettingForm = () => {
        setToggle(true)
        createCodEnable(shop)
    }

    return (
        <>
            {
                toggle
                    ? <SettingForm shop={getShop}/>
                    : <InitializeForm submit={toSettingForm}/>
            }
        </>
    )
}

export default App;
